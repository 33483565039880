
* {
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  
  color: #333;
  overflow-x: hidden;
  
}

.App {
padding-top: 100px;
  background-color: #000000;
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  padding: 20px;
}

/* Add some cool animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

main > * {
  animation: fadeIn 1.5s ease-in-out;
}

/* Responsive design */
@media (max-width: 768px) {
  main {
    padding: 15px;
  }

  .App-header, .App-footer {
    padding: 15px;
  }
}

/* Additional styles and animations can be added as per your preference */
.container {
  max-width: 100vw;
  overflow-x: hidden;
}


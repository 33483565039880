/* BlogPreview.css */

.blog-preview {
    background: linear-gradient(0deg, #ffafe4, rgb(206, 206, 206)); /* Light background for each preview */
    
    
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Spacing inside each preview box */
    margin: 20px 0; /* Space between each preview */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .blog-preview:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
  }
  
  .blog-preview h2 {
    color: #d13081; /* Gorilla Funk themed color */
    margin-bottom: 10px; /* Space below the title */
    font-size: 1.8em; /* Emphasized title size */
  }
  
  .blog-preview p {
    font-style: italic;
    color: #000000; /* Subdued color for date */
    margin-bottom: 15px; /* Space below the date */
  }
  
  .blog-excerpt {
    color: #f1f1f1; /* Darker text color for contrast */
    margin-bottom: 20px; /* Space above the 'Read More' link */
  }
  
  .blog-preview a {
    text-decoration: none;
    color: #d13081; /* Link color matching the theme */
    font-weight: bold;
    background-color: #dfdfdf;
    padding: 8px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .blog-preview a:hover {
    background-color: #d13081;
    color: #fff; /* Color change on hover for interactivity */
  }
  
  .mainlogo {
    width: 20vh; /* Full width */
    height: 20vh; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners */
    margin: 20px 0; /* Margin for spacing */
  }
.App-footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  
  width: 100%;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px 10px; /* Adjusted padding for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes colorChange {
  0%, 100% { background-color: #cdcfd33b; }
  50% { background-color: #ff12c4b9; }
}

.logo {
  height: 100px;
  animation: logoFloat 3s ease-in-out infinite;
}

@keyframes logoFloat {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.social-icon {
  color: white;
  font-size: 20px;
  margin: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.3);
  color: #e1306c;
}

.contact-info {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .logo {
    height: 100px; /* Slightly smaller logo for mobile */
  }

  .social-icon {
    font-size: 15px; /* Adjust icon size for mobile */
  }

  .App-footer {
    padding: 15px 5px; /* Reduce padding on smaller screens */
    align-items: flex-end;
  }

  .contact-info {
    font-size: 0.8em; /* Slightly smaller text for mobile */
  }
}

.underlinegone {
  text-decoration: none;
  color: white;
}
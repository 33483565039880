.blog-post {
    background-color: #282c34; /* Dark background for contrast */
    color: white; /* White text for readability */
    margin: 20px auto; /* Centering the blog post */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    max-width: 800px; /* Max width for readability */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  }
  
  .blog-post h1 {
    color: #d13081; /* Vibrant Gorilla Funk color */
    text-align: center; /* Centering the title */
    margin-bottom: 1em; /* Spacing after the title */
  }
  
  .blog-date {
    font-style: italic;
    color: #ccc; /* Lighter color for the date */
    margin-bottom: 2em; /* Spacing after the date */
  }
  
  .blog-content {
    text-align: left; /* Align text to the left for readability */
    line-height: 1.6; /* Line height for readability */
  }
  
  .blog-content h2 {
    color: #e8e8e8; /* Light color for subheadings */
    margin-top: 2em; /* Spacing before each subheading */
  }
  
  .blog-content p {
    margin-bottom: 1em; /* Spacing after paragraphs */
  }
  
  .blog-content ul {
    list-style: inside square; /* Stylish square bullet points */
  }
  
  .blog-content li {
    margin-bottom: 0.5em; /* Spacing between list items */
  }

  .blog-img {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners */
    margin: 20px 0; /* Margin for spacing */
  }
  
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .blog-post {
      padding: 15px;
      margin: 10px; /* Smaller margin for smaller screens */
    }
  
    .blog-post h1 {
      font-size: 1.8em; /* Smaller font size for smaller screens */
    }
  }
  
.music-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    border: none;
    background-color: #9b1594;
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    font-size: 15px;
    max-width: 100px;
    cursor: pointer;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    font-family: "Anton", sans-serif;
    
  }
  
  .music-button:hover {
    background-color: #020202;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
   
    z-index: 9999;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #8d929b;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
  }

  .modal-content h2 {
    color: #020202;
  }

  .modal-content button {
    font-family: "Anton", sans-serif;
  }
  
  .close-button {
    color: #020202;
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 44px;
    cursor: pointer;
    z-index: 9999;
  }
  
  .controls button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #9b1594;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .controls button:hover {
    background-color: #020202;
  }

  .current-info {
    color: #020202;
  }
  
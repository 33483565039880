@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

body {
  margin: 0;
  font-family: "Anton", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: "Anton", sans-serif;
}

h2  {
  font-family: "Anton", sans-serif;
}


h3  {
  font-family: "Anton", sans-serif;
}

p {
  font-family: "Anton", sans-serif;
}

.white-text {
  color: white;
  font-weight: 300;
  font-size: 2rem;
}